.map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.sidebarStyle {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  background-color: #404040;
  color: #ffffff;
  z-index: 1 !important;
  padding: 6px;
  font-weight: bold;
}
.rectangle-image{
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.rectangle-sold{
  width: 10px;
  height: 20px;
  background-color: blue;
}
.pop-custom-button { 
  color: #222222; 
  padding: 1px 5px; 
  margin-right: 10px;
  margin-top: 5px;
  border:solid 1px #222222; 
  border-radius: 4px; 
  cursor: pointer;
}
.markerStyle{
  /* position: 'absolute'; */
  width: '50px';
  height: '50px';
  /* left: '-25px';
  top: '-25px'; */
  border: '2px solid #fff';
  border-radius: '50%';
  text-align: 'center';
  color: '#fff';
  /* padding: '15px'; */
  cursor: 'pointer';
  box-shadow: '0 0 0 2px rgba(0,0,0,0.5)';
  background-size: 'cover';
}
.triangleStyle{
  position: 'absolute';
  bottom: '-10px';
  left: '50%';
  margin-left: '-10px';
  width: '0';
  height: '0';
  border-left: '10px solid transparent';
  border-right: '10px solid transparent';
  border-top: '10px solid #007cbf';
}