.tick {
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-bottom-color: green;
    border-right-color: green;
    transform: rotate(45deg);
    }
    .geojson-rectangle {
        text-align:center;
        cursor:pointer;
    }