.option {
    padding-left: 5px;
    padding-right: 5px;
  }
  .top-bar {
    max-width: 380px;
  }
  .option:hover {
    background-color: rgb(209, 205, 205);
  }
  .boxshadw{
    box-shadow: 0 0 0 2px rgba(0,0,0,.1);
  }
  .custom-button:hover {
    color:black !important;
    border-color: black !important;
}
.bottom-right-bar{
  display: flex;
  align-items: center; 
  height: 30px;
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 999;
  background: rgb(255, 255, 255);
  border: 0px;
  border-radius: 4px;
  padding-left: 10px;
}
.square {
  width: 16px;
  height: 16px;
  border: 3px solid;
  }
  .first-square {
  border-color: #00ff00; 
  }
  .second-square {
  border-color: #e95b5a;
  }
  .text {
  text-align: center;
  margin: 0 10px;
  }
  @media (max-width: 600px) {
    .top-bar {
      flex-wrap: wrap;
      max-width: 230px;
    }
    .input-button-wrapper {
      width: 100%;
      justify-content: normal;
    }
  }
  